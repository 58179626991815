import { FC } from 'react'

export const WarningIconNotificationDark: FC = () => (
  <>
    <rect x="0" y="0" width="24" height="24" rx="12" fill="#aa5403" />

    <path
      d="M12 15.5C11.8619 15.5 11.75 15.6119 11.75 15.75C11.75 15.8881 11.8619 16 12 16C12.1381 16 12.25 15.8881 12.25 15.75C12.25 15.6119 12.1381 15.5 12 15.5Z"
      stroke="#fff9ec"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <path
      d="M12 13V9"
      stroke="#fff9ec"
      strokeLinecap="round"
      strokeLinejoin="round"
    />

    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.85 6.75C12.659 6.3905 12.341 6.25 12 6.25C11.659 6.25 11.341 6.3905 11.15 6.75L6.3 16.25C6.14 16.54 6.16 16.91 6.36 17.15C6.56 17.39 6.89 17.5 7.2 17.5H16.8C17.11 17.5 17.44 17.39 17.64 17.15C17.84 16.91 17.86 16.54 17.7 16.25L12.85 6.75Z"
      stroke="#fff9ec"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default WarningIconNotificationDark
