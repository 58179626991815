import { AnalyticsRequestUser } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'

export const user: AnalyticsRequestUser = user => {
  const mapUserLevel = (userLevel: string | undefined): string => {
    const hasNewMyBarceloLoyalties = isFeatureFlagEnabled(
      'FF_FEATURE_5847_MY_BARCELO_REVAMP',
    )
    switch (userLevel) {
      case '1':
        return hasNewMyBarceloLoyalties ? 'essential' : 'start'
      case '2':
        return hasNewMyBarceloLoyalties ? 'special' : 'intense'
      case '3':
        return 'unique'
      default:
        return 'notFound'
    }
  }

  const linkParams = {
    tealium_event: 'cbe_user_logged_in',
    event_action: 'login',
    event_category: 'mybarcelo',
    event_label: 'cbe_app',
    user_is_logged: '1',
    user_type: 'logged',
    user_logged: true,
    user_signup_recently: false,
    user_agent_property: window.navigator.userAgent,
    user_culture: window.navigator.language,
    user_myb_email: user.email,
    user_myb_discount: user.discount,
    user_my_barcelo_level: user.level,
    user_name: user.givenName,
    user_middlename: user.middleName,
    user_surname: user.familyName,
    user_address: user.address,
    user_city: user.city,
    user_myb_id: user.crmId,
    user_crs_id: user.crsId,
    user_myb_level: mapUserLevel(user.profileLevelCode),
    user_navigator_language: window.navigator.language,
    user_phone_number: user.phoneNumber,
    user_postal_code: user.postalCode,
    user_province: user.state,
  }
  container.resolve('logger').info('Analytics/links/requests/user', {
    linkParams,
    utag_data: window.utag_data,
  })
  window.utag.link(linkParams)
}
