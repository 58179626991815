import { FC } from 'react'
import { Text } from 'src/ui/components/atoms/Text'
import { Flex } from 'src/ui/styles/objects/Flex'
import styles from './NotificationContent.module.scss'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { NotificationTypes } from '../NotificationTypes.model'
import {
  SuccessIconNotificationDark,
  InfoIconNotificationDark,
  ErrorIconNotificationDark,
  WarningIconNotificationDark,
  SuccessIconNotificationLight,
  InfoIconNotificationLight,
  ErrorIconNotificationLight,
  WarningIconNotificationLight,
} from 'src/ui/components/atoms/Icon/_icons/notifications/'

interface Props {
  title: string
  type: NotificationTypes
  description?: string
  isDarkVariant: boolean
}

export const NotificationContent: FC<Props> = ({
  title,
  description,
  type,
  isDarkVariant,
}) => {
  const darkIconsByType = {
    success: SuccessIconNotificationDark,
    error: ErrorIconNotificationDark,
    warning: WarningIconNotificationDark,
    info: InfoIconNotificationDark,
  }

  const lightIconsByType = {
    success: SuccessIconNotificationLight,
    error: ErrorIconNotificationLight,
    warning: WarningIconNotificationLight,
    info: InfoIconNotificationLight,
  }

  return (
    <section className={styles.notificationContent}>
      <Flex direction="row" gap="s" alignItems="flex-start">
        {type && (
          <Icon
            size="l"
            icon={
              isDarkVariant ? lightIconsByType[type] : darkIconsByType[type]
            }
          />
        )}
        <Flex direction="column" alignItems="flex-start">
          <Text fontStyle="m-500" color={isDarkVariant ? 'light' : 'dark'}>
            {title}
          </Text>
          {description && (
            <Text fontStyle="s-300" color={isDarkVariant ? 'light' : 'dark'}>
              {description}
            </Text>
          )}
        </Flex>
      </Flex>
    </section>
  )
}
