import { Time } from 'src/core/Shared/infrastructure/Time'
import { AnalyticsRequestReservation } from 'src/core/Shared/domain/Analytics'
import { container } from 'src/core/Shared/_di'
import { Reservation } from 'src/core/Shared/domain/Reservation.model'
import { datesManager } from 'src/core/Shared/infrastructure/datesManager'

export const preReservation: AnalyticsRequestReservation = (
  preReservation,
  coupon,
  groupcode,
) => {
  const linkParams = {
    ga4_event_name: 'cta',
    event: 'cta',
    event_category: 'Booking Process',
    event_action: 'Room Selection',
    event_label: 'Room Selected',

    availability_link: false,
    ...buildUtagDataForReservation(preReservation, coupon, groupcode),
  }
  container.resolve('logger').info('Analytics/links/requests/preReservation', {
    linkParams,
    utag_data: window.utag_data,
  })
  window.utag.link(linkParams)
}

export function buildUtagDataForReservation(
  reservation: Reservation,
  coupon?: string,
  groupcode?: string,
) {
  return {
    booking_adults: reservation.occupancy.adults,
    booking_nights: datesManager.calculateNights(
      reservation.checkIn,
      reservation.checkOut,
    ),
    booking_availability: !!reservation ? '1' : '0',
    booking_board_basis: reservation.roomStays
      .map(roomStay => roomStay.rate.mealPlan.id)
      .join(','),
    booking_check_in_date: Time.fromDate(reservation.checkIn).format(
      'YYYYMMDD',
    ),
    booking_check_out_date: Time.fromDate(reservation.checkOut).format(
      'YYYYMMDD',
    ),
    booking_children: reservation.occupancy.children,
    booking_hotel_currency_price: reservation.prices.total.original.value,
    booking_hotel_currency: reservation.prices.total.original.currency,
    booking_hotel_id: reservation.hotel.id,
    booking_hotel_name: reservation.hotel.name,
    booking_room_type_id: reservation.roomStays
      .map(roomStay => roomStay.room.id)
      .join(','),
    booking_room_type: reservation.roomStays
      .map(roomStay => roomStay.room.name)
      .join(','),
    booking_rate_code: reservation.roomStays
      .map(roomStay => roomStay.rate.id)
      .join(','),
    booking_room_price: reservation.roomStays
      .map(roomStay => roomStay.rate.total.grossPrice.value)
      .join(','),
    booking_room_quantity: reservation.roomStays.length,
    booking_room_rate_type: reservation.roomStays
      .map(roomStay => (roomStay.rate.isMember ? 'myBarcelo' : 'Standard'))
      .join(','),

    booking_price_exchange: reservation.prices.total.converted.value,
    booking_user_currency_price: reservation.prices.total.converted.value,
    booking_user_currency: reservation.prices.total.converted.currency,

    booking_total_guests: !!reservation
      ? reservation.occupancy.adults + reservation.occupancy.children
      : undefined,
    booking_tax: reservation.prices.tax.original.value,
    coupon: coupon,
    groupcode: groupcode,
  }
}
