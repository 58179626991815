import { Format, Time } from 'src/core/Shared/infrastructure/Time'
import { CheckInCheckOut } from '../domain/CheckInCheckOut'
import { container } from '../_di'
import { isUndefined } from './wrappers/javascriptUtils'

export const MAX_NIGHTS = 46
const MAX_MONTHS = 36
const SERVER_TIMEZONE = 'Europe/London'

export const datesManager = {
  areDatesTheSame: (firstDate: Date, secondDate: Date): boolean =>
    Time.fromDate(firstDate).isSame(Time.fromDate(secondDate), 'day') &&
    Time.fromDate(firstDate).isSame(Time.fromDate(secondDate), 'month') &&
    Time.fromDate(firstDate).isSame(Time.fromDate(secondDate), 'year'),
  areCheckInAndCheckOutValid: (checkIn: string, checkOut: string) => {
    const checkInTime = Time.fromStringWithFormat(checkIn, 'YYYY-MM-DD')
    const checkOutTime = Time.fromStringWithFormat(checkOut, 'YYYY-MM-DD')
    const datesAreDifferent = !datesManager.areDatesTheSame(
      checkInTime.toDate(),
      checkOutTime.toDate(),
    )
    const isDifferenceLessThanMaxNights =
      Math.abs(checkInTime.diff(checkOutTime, 'day')) < MAX_NIGHTS

    if (!datesAreDifferent) {
      container
        .resolve('logger')
        .error(
          new Error('Query params - dates are invalid: dates are the same'),
        )
    }
    if (!checkInTime.isBefore(checkOutTime)) {
      container
        .resolve('logger')
        .error(
          new Error(
            'Query params - dates are invalid: check in is after check out',
          ),
        )
    }
    if (!isDifferenceLessThanMaxNights) {
      container
        .resolve('logger')
        .error(
          new Error(
            'Query params - dates are invalid: difference between check in and check out is more than max nights',
          ),
        )
    }

    return (
      datesAreDifferent &&
      checkInTime.isBefore(checkOutTime) &&
      isDifferenceLessThanMaxNights &&
      datesManager.isBetweenValidDates(checkOutTime) &&
      datesManager.isBetweenValidDates(checkInTime)
    )
  },
  isBetweenValidDates: (time: Time) => {
    const firstDayAvailable = getFirstDayAvailable(time)
    const lastDayAvailable = getFirstDayAvailable(time).add(MAX_MONTHS, 'month')

    return time.isBetweenDaysIncluded(firstDayAvailable, lastDayAvailable)
  },
  getDefaultDates: (
    time: Time = Time.now(),
  ): { arrive: string; depart: string } => {
    const DATE_FORMAT = 'YYYY-MM-DD'
    const arrive = getFirstDayAvailable(time).format(DATE_FORMAT)
    const depart = getFirstDayAvailable(time).add(1, 'day').format(DATE_FORMAT)

    return {
      arrive,
      depart,
    }
  },
  getMaxDate: () => {
    const now = Time.now()

    return now.add(MAX_MONTHS, 'month').endOf('month')
  },
  getMinDate: () => {
    const now = Time.now()

    return now.startOf('month')
  },
  formatDateRangeToLocaleWithoutYear: (dates: CheckInCheckOut) => {
    const checkInTime = Time.fromDate(dates.checkIn)
    const checkOutTime = Time.fromDate(dates.checkOut)
    const dayAndMonth = 'D MMM'

    return `${checkInTime.format(dayAndMonth)} - ${checkOutTime.format(
      dayAndMonth,
    )}`
  },
  formatDateToLocale: (
    date: Date,
    format: Format,
    monthFormat?: Format,
  ): string => {
    const time = Time.fromDate(date)
    if (format === 'short') {
      return time.format(`D ${getMonthFormat(monthFormat)} YYYY`)
    }

    return time.format(`dddd, D ${getMonthFormat(monthFormat)} YYYY`)
  },
  calculateNights: (checkIn: Date, checkOut: Date): number => {
    const checkInTime = Time.fromDate(checkIn)
    const checkOutTime = Time.fromDate(checkOut)

    return checkOutTime.diff(checkInTime, 'day')
  },
  formatDateRangeToLocaleWithYear: (
    dates: CheckInCheckOut,
    format: Format = 'short',
  ) => {
    const checkInTime = Time.fromDate(dates.checkIn)
    const checkOutTime = Time.fromDate(dates.checkOut)

    if (checkInTime.isSameYear(checkOutTime)) {
      return `${checkInTime.format(
        `D ${getMonthFormat(format)}`,
      )} - ${checkOutTime.format(`D ${getMonthFormat(format)}, YYYY`)}`
    }

    return `${checkInTime.format(
      `D ${getMonthFormat(format)}, YYYY`,
    )} - ${checkOutTime.format(`D ${getMonthFormat(format)}, YYYY`)}`
  },
  formatDateRangeToLocaleWithYearAndWeekDay: (
    dates: CheckInCheckOut,
    format: Format = 'short',
  ) => {
    const checkInTime = Time.fromDate(dates.checkIn)
    const checkOutTime = Time.fromDate(dates.checkOut)

    if (checkInTime.isSameYear(checkOutTime)) {
      return `${checkInTime.format(
        `ddd, D ${getMonthFormat(format)}`,
      )} - ${checkOutTime.format(`ddd, D ${getMonthFormat(format)}, YYYY`)}`
    }

    return `${checkInTime.format(
      `ddd, D ${getMonthFormat(format)}, YYYY`,
    )} - ${checkOutTime.format(`ddd, D ${getMonthFormat(format)}, YYYY`)}`
  },
  getFirstDayAvailable: () => {
    return getFirstDayAvailable(Time.now())
  },
  formatDateToLocaleWithYearAndWeekDay: (
    date?: Date,
    format: Format = 'short',
  ) => {
    if (isUndefined(date)) {
      return
    }

    const dateTime = Time.fromDate(date)
    const formattedDate = dateTime.format(
      `ddd D ${getMonthFormat(format)} YYYY`,
    )

    return `${formattedDate}`
  },
}

const getMonthFormat = (format: Format | undefined) => {
  const monthFormatMap = {
    short: 'MMM',
    long: 'MMMM',
  }
  return monthFormatMap[format ?? 'short']
}

const getFirstDayAvailable = (time: Time): Time => {
  if (!time.isToday()) {
    return Time.now()
  }

  if (isServerDayValid()) {
    return Time.now()
  }

  return Time.tomorrow()
}

const isServerDayValid = () => {
  const dayFormat = 'D'
  const userDay = Time.now().format(dayFormat)
  const serverDay = Time.now().tz(SERVER_TIMEZONE).format(dayFormat)
  return userDay === serverDay
}
