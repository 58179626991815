import { FC, ReactNode } from 'react'

import styles from './Card.module.scss'
import { classNames } from 'src/ui/utils/classnames'

interface Props {
  children: ReactNode
  mobileDark?: boolean
}
export const Card: FC<Props> = ({ children, mobileDark = false }) => (
  <div
    className={classNames(styles.container, mobileDark && styles.isMobileDark)}
  >
    {children}
  </div>
)
