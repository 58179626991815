import { ReservationDTO } from 'src/core/Reservation/infrastructure/Reservation.api.dto'
import { mapRoomStays } from 'src/core/Reservation/infrastructure/mapRoomStays'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { calculateOriginalDeposit } from 'src/core/Shared/infrastructure/mappers/calculateOriginalDeposit'
import { calculateTotalGuarantee } from 'src/core/Shared/infrastructure/mappers/calculateTotalGuarantee'
import { calculateTotalPrice } from 'src/core/Shared/infrastructure/mappers/calculateTotalPrice'
import { mapPriceDTO } from 'src/core/Shared/infrastructure/mappers/mapPriceDTO'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { Cart } from '../domain/Cart.model'

export const mapReservationDTOIntoCart = (
  reservationDTO: ReservationDTO,
): Cart => {
  const { itineraryNumber, roomStays, summary } = reservationDTO

  const firstRoomStay = roomStays[0]
  const priceOptions = { getConverted: true }

  const mappedRoomStays = mapRoomStays(roomStays, priceOptions)

  return {
    itineraryNumber: itineraryNumber,
    checkIn: Time.fromString(firstRoomStay.startDate).toDate(),
    checkOut: Time.fromString(firstRoomStay.endDate).toDate(),
    hotelName: firstRoomStay.hotel.details.name,
    hotelId: firstRoomStay.hotel.id,
    occupancy: roomStays.reduce(
      (prev, stay) => ({
        adults: prev.adults + stay.occupancy.adults,
        children: prev.children + (stay.occupancy.children ?? 0),
      }),
      { adults: 0, children: 0 },
    ),
    prices: {
      total: calculateTotalPrice(summary.total),
      tax: {
        original: mapPriceDTO(summary.tax, { getConverted: false }),
      },
      guarantee: calculateTotalGuarantee(roomStays, priceOptions),
      originalDeposit: calculateOriginalDeposit(roomStays),
    },
    roomStays: mappedRoomStays,
    summary: {
      netDiscount: isDefined(summary.discountBeforeTax)
        ? mapPriceDTO(summary.discountBeforeTax, priceOptions)
        : undefined,
      grossDiscount: isDefined(summary.discountAfterTax)
        ? mapPriceDTO(summary.discountAfterTax, priceOptions)
        : undefined,
    },
    isExpired: reservationDTO.status !== 'Pending',
    isCommitted: reservationDTO.status === 'Committed',
    isGuestIdCardRequired: reservationDTO.isGuestIdCardRequired,
    guaranteedByName: reservationDTO.isGuaranteeCustomerName,
    rateCurrencies: roomStays.map(
      roomStay => roomStay.rate.stay.total.currency,
    ),
    isGuestMember: roomStays.some(roomStay =>
      roomStay.guests.some(guest => guest.blocked),
    ),
    marketCampaign: reservationDTO.marketCampaign,
    customerComments: reservationDTO.comment,
  }
}
