import { FC } from 'react'

export const WarningIconNotificationLight: FC = () => (
  <>
    <rect x="1" y="1.00439" width="22" height="22" rx="11" fill="#FFF9EC" />
    <path
      d="M12 15.1646C11.887 15.1646 11.7955 15.2561 11.7955 15.3691C11.7955 15.4821 11.887 15.5736 12 15.5736C12.113 15.5736 12.2045 15.4821 12.2045 15.3691C12.2045 15.2561 12.113 15.1646 12 15.1646V15.1646"
      stroke="#AA5403"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12 13.5279V9.43701"
      stroke="#AA5403"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8644 6.54293C12.7025 6.21327 12.3672 6.00439 11.9999 6.00439C11.6326 6.00439 11.2973 6.21327 11.1354 6.54293L6.08317 16.8352C5.95954 17.0867 5.97448 17.3842 6.12271 17.6221C6.27093 17.8599 6.53144 18.0044 6.8117 18.0043H17.1881C17.4684 18.0044 17.7289 17.8599 17.8771 17.6221C18.0253 17.3842 18.0403 17.0867 17.9166 16.8352L12.8644 6.54293Z"
      stroke="#AA5403"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default WarningIconNotificationLight
