import { routes, Routes } from 'src/ui/navigation/routes'
import { WithInjectedParams } from '../_di/types'
import { StorageClient } from './sessionStorageClient'
import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'

interface StepperManagerDependencies {
  sessionStorageClient: StorageClient
}

export enum StepId {
  AVAILABLE_ROOMS = 1,
  PERSONAL_DATA = 2,
  CHOOSE_PAYMENT = 3,
}

export interface StepperManager {
  setPersonalData: () => void
  setChoosePayment: () => void
  getMaxVisited: () => StepId
  reset: () => void
  checkMaxVisited: (
    currentStep: StepId,
    sessionMaxVisitedStep?: StepId,
  ) => StepId
  canNavigateToStep: (
    id: StepId,
    maxVisitedStep: StepId,
    currentStep: StepId,
  ) => boolean
  getCurrentStep: (path: Routes) => void
}

const key = 'maxVisitedStep'

export const stepperManager: WithInjectedParams<
  StepperManager,
  StepperManagerDependencies
> = ({ sessionStorageClient }) => ({
  setPersonalData: () => {
    setStep(sessionStorageClient, StepId.PERSONAL_DATA)
  },
  setChoosePayment: () => {
    setStep(sessionStorageClient, StepId.CHOOSE_PAYMENT)
  },
  getMaxVisited: () => {
    const result = sessionStorageClient.get(key)

    if (isUndefined(result)) {
      return StepId.AVAILABLE_ROOMS
    }

    return result as StepId
  },
  reset: () => {
    sessionStorageClient.remove(key)
  },
  checkMaxVisited: (currentStep: StepId, sessionMaxVisitedStep?: StepId) => {
    if (isUndefined(sessionMaxVisitedStep)) {
      return currentStep
    }

    if (currentStep > sessionMaxVisitedStep) {
      return currentStep
    }

    return sessionMaxVisitedStep
  },
  canNavigateToStep: (
    id: StepId,
    maxVisitedStep: StepId,
    currentStep: StepId,
  ) => {
    if (id === currentStep) {
      return false
    }

    return maxVisitedStep >= id
  },
  getCurrentStep: (path: Routes) => {
    if (path === routes.availability) {
      return StepId.AVAILABLE_ROOMS
    }

    if (path === routes.personalData) {
      return StepId.PERSONAL_DATA
    }

    if (path === routes.choosePayment) {
      return StepId.CHOOSE_PAYMENT
    }
  },
})

const setStep = (sessionStorageClient: StorageClient, step: StepId) => {
  const maxVisited = stepperManager({ sessionStorageClient }).getMaxVisited()

  if (maxVisited >= step) {
    return
  }

  sessionStorageClient.set(key, step)
}
