import { classNames } from 'src/ui/utils/classnames'
import { useSwiper } from 'swiper/react'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'

import styles from './NavigationButton.module.scss'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'
import React, { FC } from 'react'
interface Props {
  isRTL: boolean
  loop?: boolean
}

export const NavigationPreviousButton: FC<Props> = ({
  isRTL,
  loop = false,
}) => {
  const swiper = useSwiper()

  function handlePreviousSlide() {
    return !isRTL ? swiper.slidePrev?.() : swiper.slideNext?.()
  }

  if (!loop && (isRTL ? swiper.isEnd : swiper.isBeginning)) {
    return null
  }

  return (
    <div
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        handlePreviousSlide()
      }}
      className={classNames(styles.navigation, styles.leftNavigation)}
    >
      <Icon size="s" icon={ChevronIcon} rotate={-180} />
    </div>
  )
}
