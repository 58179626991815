import { FC } from 'react'

export const ErrorIconNotificationLight: FC = () => (
  <>
    <rect x="1" y="1.00439" width="22" height="22" rx="11" fill="#FCE7E7" />
    <path
      d="M12 18.0044C15.3137 18.0044 18 15.3181 18 12.0044C18 8.69069 15.3137 6.00439 12 6.00439C8.68629 6.00439 6 8.69069 6 12.0044C6 15.3181 8.68629 18.0044 12 18.0044Z"
      stroke="#960B0D"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.83334 7.83789L16.1667 16.1712"
      stroke="#960B0D"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default ErrorIconNotificationLight
