import { Time } from 'src/core/Shared/infrastructure/Time'
import { Reservation } from 'src/core/Shared/domain/Reservation.model'
import { calculateTotalPrice } from 'src/core/Shared/infrastructure/mappers/calculateTotalPrice'
import { calculateTotalGuarantee } from 'src/core/Shared/infrastructure/mappers/calculateTotalGuarantee'
import { calculateOriginalDeposit } from 'src/core/Shared/infrastructure/mappers/calculateOriginalDeposit'
import { mapRoomStays } from './mapRoomStays'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { mapPriceDTO } from 'src/core/Shared/infrastructure/mappers/mapPriceDTO'
import { ReservationDTO } from './Reservation.api.dto'
import { PriceOptions } from 'src/core/Shared/domain/Price.model'

export const mapReservation = (
  reservationDTO: ReservationDTO,
  priceOptions: PriceOptions,
): Reservation => {
  const {
    id,
    itineraryNumber,
    status,
    isGuestIdCardRequired,
    isGuaranteeCustomerName,
    roomStays,
    summary,
    marketCampaign,
    occupancy,
  } = reservationDTO

  const firstRoomStay = roomStays[0]
  const mappedRoomStays = mapRoomStays(roomStays, priceOptions)

  return {
    id,
    itineraryNumber,
    status,
    hotel: {
      id: firstRoomStay.hotel.id,
      name: firstRoomStay.hotel.details.name,
    },
    checkIn: Time.fromString(firstRoomStay.startDate).toDate(),
    checkOut: Time.fromString(firstRoomStay.endDate).toDate(),
    roomsNumber: roomStays.length,
    occupancy: {
      adults: occupancy.adults,
      children: occupancy.children ?? 0,
      childrenAges: occupancy.childrenAges,
    },
    prices: {
      total: calculateTotalPrice(summary.total),
      guarantee: calculateTotalGuarantee(roomStays, priceOptions),
      originalDeposit: calculateOriginalDeposit(roomStays),
      tax: {
        original: mapPriceDTO(summary.tax, { getConverted: false }),
      },
    },
    isGuestIdCardRequired,
    isGuestMember: roomStays.some(stay =>
      stay.guests.some(guest => guest.blocked),
    ),
    guaranteedByName: isGuaranteeCustomerName,
    roomStays: mappedRoomStays,
    summary: {
      netDiscount: isDefined(summary.discountBeforeTax)
        ? mapPriceDTO(summary.discountBeforeTax, priceOptions)
        : undefined,
      grossDiscount: isDefined(summary.discountAfterTax)
        ? mapPriceDTO(summary.discountAfterTax, priceOptions)
        : undefined,
    },
    rateCurrencies: roomStays.map(
      roomStay => roomStay.rate.stay.total.currency,
    ),
    marketCampaign: marketCampaign,
    comment: reservationDTO.comment,
  }
}
