import { FC } from 'react'

export const SuccessIconNotificationLight: FC = () => (
  <>
    <rect x="1" y="1.00439" width="22" height="22" rx="11" fill="#E8F1E9" />
    <path
      d="M18 7.33789L10.8816 16.829C10.7194 17.0456 10.4711 17.181 10.2012 17.2002C9.93128 17.2194 9.66633 17.1206 9.47501 16.9292L6 13.4542"
      stroke="#0F5317"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default SuccessIconNotificationLight
