import { FC } from 'react'

export const InfoIconNotificationLight: FC = () => (
  <>
    <rect x="1" y="1.00439" width="22" height="22" rx="11" fill="#E5F4FD" />
    <path
      d="M12 14.4044V12.0044M12 9.60439H12.006M18 12.0044C18 15.3181 15.3137 18.0044 12 18.0044C8.68629 18.0044 6 15.3181 6 12.0044C6 8.69069 8.68629 6.00439 12 6.00439C15.3137 6.00439 18 8.69069 18 12.0044Z"
      stroke="#004B81"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default InfoIconNotificationLight
