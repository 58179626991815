import { FC, ReactNode } from 'react'
import styles from './AsideModal.module.scss'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import { useEscapeOnClose } from 'src/ui/hooks/useEscapeOnClose'
import { useTrans } from 'src/ui/hooks/useTrans'
import { isDefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useModal } from 'src/ui/hooks/useModal'
import { useLockBodyScroll } from 'src/ui/hooks/useLockBodyScroll'
import { CloseIcon } from 'src/ui/components/atoms/Icon/_icons/interaction/CloseIcon'
import ReactModal from 'react-modal'
import { Text } from '../../atoms/Text'

interface Props {
  children: ReactNode
  title: string
  onCloseIconClick?: () => void
  onClose?: () => void
  'data-testid'?: string
  scrollable?: boolean
}

export const AsideModal: FC<Props> = ({
  children,
  title,
  onCloseIconClick,
  onClose,
  'data-testid': testId,
  scrollable = false,
}) => {
  const { trans } = useTrans(['common'])
  const { visibleModal, hideModal, removeModal } = useModal()
  const { lockBodyScroll, releaseBodyScroll } = useLockBodyScroll()

  const close = () => {
    hideModal()
    onClose?.()
  }

  useEscapeOnClose({ onClose: close })

  // It is important for users of screenreaders that other page content be hidden while the modal is open
  // https://reactcommunity.org/react-modal/accessibility/
  if (
    process.env.NODE_ENV !== 'test' &&
    document.getElementById('__next') !== null
  ) {
    ReactModal.setAppElement('#__next')
  }

  return (
    <ReactModal
      isOpen={visibleModal}
      className={styles.modalContainer}
      overlayClassName={styles.overlay}
      closeTimeoutMS={400}
      onAfterOpen={() => {
        lockBodyScroll()
      }}
      onAfterClose={() => {
        releaseBodyScroll()
        removeModal()
      }}
      onRequestClose={() => {
        close()

        if (isDefined(onCloseIconClick)) {
          onCloseIconClick()
        }
      }}
      data-testid={testId}
    >
      <div className={styles.modalHeader}>
        <Text fontStyle="l-700">{title}</Text>
        <button
          className={styles.exitButton}
          onClick={() => {
            close()

            if (isDefined(onCloseIconClick)) {
              onCloseIconClick()
            }
          }}
          aria-label={trans('modal_close-button_aria-label')}
        >
          <Icon size="l" icon={CloseIcon} />
        </button>
      </div>
      <div
        className={
          scrollable ? styles.modalContentScrollable : styles.modalContent
        }
      >
        {children}
      </div>
    </ReactModal>
  )
}
