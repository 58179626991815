import { AnalyticsActionsExtrasRemoveExtra } from 'src/core/Shared/domain/Analytics'
import { isAvailabilityExtraPerGuest } from 'src/core/Availability/domain/Availability.model'
import {
  head,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { isPendingExtraPerUnit } from 'src/core/Cart/domain/Cart.model'
import { container } from 'src/core/Shared/_di'

export const removeExtra: AnalyticsActionsExtrasRemoveExtra = (
  extras,
  hasAvailability,
) => {
  const extra = head(extras)?.pendingExtra.extra

  if (isUndefined(extra)) {
    return
  }

  const totalUnits = extras.map(extra => {
    if (isPendingExtraPerUnit(extra.pendingExtra)) {
      return extra.pendingExtra.count
    }

    return extra.pendingExtra.adultsCount + extra.pendingExtra.childrenCount
  })

  const totalPricePerGuests = extras.map(extra => {
    if (isPendingExtraPerUnit(extra.pendingExtra)) {
      return (
        extra.pendingExtra.count *
        extra.pendingExtra.extra.price.grossPrice.value
      )
    }

    return (
      extra.pendingExtra.adultsCount *
        extra.pendingExtra.extra.adultsPrice.grossPrice.value +
      extra.pendingExtra.childrenCount *
        (extra.pendingExtra.extra.childrenPrice?.grossPrice.value ?? 0)
    )
  })

  const totalPrice = totalPricePerGuests.reduce((a, b) => a + b, 0)

  const linkParams = {
    ga4_event_name: 'delete_extras',
    event: 'delete_extras',
    event_category: 'extras',
    event_action: 'click on delete extras button',
    event_label: 'extras deleted from cart',
    extra_dispo: hasAvailability,
    extra_name: extra.name,
    extra_id: extra.id,
    extra_units: totalUnits,
    extra_price: totalPricePerGuests,
    extra_total_price: totalPrice,
    extra_currency: isAvailabilityExtraPerGuest(extra)
      ? extra.adultsPrice.grossPrice.currency
      : extra.price.grossPrice.currency,
  }
  container
    .resolve('logger')
    .info('Analytics/links/actions/extras/removeExtra', {
      linkParams,
      utag_data: window.utag_data,
    })
  window.utag.link(linkParams)
}
