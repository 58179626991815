import { ViewingStatsDTO } from 'src/core/Reservation/infrastructure/Reservation.api.dto'
import { ViewingAccelerator } from 'src/core/Shared/domain/Reservation.model'

export const mapViewingStats = (
  viewingStats: ViewingStatsDTO,
): ViewingAccelerator[] => {
  return viewingStats.stats.map(viewingStat => {
    return {
      roomTypeId: viewingStat.roomTypeId,
      count: viewingStat.pendingCount,
    }
  })
}
