import { FC, ReactNode } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import styles from './Tag.module.scss'

interface Props {
  children: ReactNode
  className?: string
  variant?: 'light' | 'bold'
}

export const Tag: FC<Props> = ({
  children,
  className,
  variant = 'light',
  ...props
}) => {
  return (
    <div className={classNames(styles[variant], className)} {...props}>
      {children}
    </div>
  )
}
