import type { WithInjectedParams } from 'src/core/Shared/_di/types'
import type { StorageClient } from 'src/core/Shared/infrastructure/sessionStorageClient'

interface ContactManagerDependencies {
  sessionStorageClient: StorageClient
}

export interface ContactStorageRepository {
  getCommercialCommunicationsUpdated: () => boolean | undefined
  reset: () => void
}

export const contactStorageRepository: WithInjectedParams<
  ContactStorageRepository,
  ContactManagerDependencies
> = ({ sessionStorageClient }) => ({
  getCommercialCommunicationsUpdated: () => {
    return sessionStorageClient.get<boolean | undefined>(
      'areCommercialCommunicationsUpdated',
    )
  },
  reset: () => {
    sessionStorageClient.remove('areCommercialCommunicationsUpdated')
  },
})
