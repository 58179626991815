import {
  CreateReservationRequestDTO,
  IDCardDTO,
  ReservationDTO,
  ViewingStatsDTO,
} from './Reservation.api.dto'
import { ReservationRepository } from 'src/core/Reservation/domain/Reservation.repository'
import {
  Address,
  appliesPromotionalCouponInReservation,
  Reservation,
  ViewingAccelerator,
} from 'src/core/Shared/domain/Reservation.model'
import { ReservationGuestDTO } from './ReservationGuest.api.dto'
import { WithInjectedParams } from 'src/core/Shared/_di/types'
import {
  isDefined,
  isUndefined,
  omit,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { IdCardError } from 'src/core/Reservation/domain/IdCardError'
import {
  CurrencyISOCode,
  PriceOptions,
} from 'src/core/Shared/domain/Price.model'
import { ReservationApiClient } from 'src/core/Shared/infrastructure/reservationApiClient'
import {
  CustomEvent,
  EventsManager,
} from 'src/core/Shared/infrastructure/eventsManager'
import { UnauthorizedError } from 'src/core/Shared/domain/UnauthorizedError'
import {
  ReservationFields,
  ReservationStorageRepository,
} from 'src/core/Reservation/infrastructure/Reservation.storage.repository'
import { Coupon } from 'src/core/Reservation/domain/Coupon'
import { CannotDeleteCouponError } from 'src/core/Reservation/infrastructure/CannotDeleteCouponError'
import { Analytics } from 'src/core/Shared/domain/Analytics'
import { ModifyExtrasRequestBodyDTO } from 'src/core/Reservation/infrastructure/ExtrasRequest.api.dto'

import { AxiosError } from 'axios'
import {
  ReservationError,
  ReservationErrorType,
} from 'src/core/Reservation/domain/ReservationError'

import { ItineraryNumberError } from 'src/core/Reservation/domain/ItineraryNumberError'
import { mapReservation } from './mapReservation'
import { mapApplyExtrasRequest } from './mapApplyExtras'
import { Cart } from 'src/core/Cart/domain/Cart.model'
import { mapCartIntoReservationCriteria } from 'src/core/Cart/infrastructure/mapCartIntoReservationCriteria'
import { ReservationCriteria } from 'src/core/Reservation/domain/ReservationCriteria'
import { mapViewingStats } from 'src/core/Reservation/infrastructure/mapViewingStats'
import { Time } from 'src/core/Shared/infrastructure/Time'
import { Logger } from 'src/core/Shared/domain/Logger'

interface RepositoryDependencies {
  reservationApiClient: ReservationApiClient
  eventsManager: EventsManager
  reservationStorageRepository: ReservationStorageRepository
  analytics: Analytics
  logger: Logger
}

export const apiReservationRepository: WithInjectedParams<
  ReservationRepository,
  RepositoryDependencies
> = ({
  reservationApiClient,
  eventsManager,
  reservationStorageRepository,
  analytics,
  logger,
}) => ({
  getByIdAndItineraryNumber: async (
    reservationId,
    itineraryNumber,
    token,
    customerCurrency,
    priceOptions,
  ) => {
    try {
      const reservationDTO = await reservationApiClient
        .modifiesReservation(itineraryNumber)
        .authorized(token)
        .get<ReservationDTO>(`/v1/reservations/${reservationId}`, {
          headers: {
            'X-CBE-Customer-Currency': customerCurrency,
          },
        })

      return mapReservation(reservationDTO, priceOptions)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 410) {
          throw new ReservationError(
            error.response?.data?.message ?? 'Unknown error',
            ReservationErrorType.NON_EXISTENT,
          )
        }
      }
      throw error
    }
  },
  create: async (reservationCriteria, token, currency, priceOptions) => {
    try {
      const reservationFields =
        reservationStorageRepository.getUnsafeReservationFields()
      if (isDefined(reservationFields)) {
        return await tryToCreateWithRollback(
          reservationCriteria,
          reservationApiClient,
          reservationFields,
          token,
          currency,
          priceOptions,
        )
      }

      return await createWithoutRollback(
        reservationCriteria,
        reservationApiClient,
        token,
        currency,
        priceOptions,
      )
    } catch (error) {
      if (error instanceof AxiosError) {
        if (
          error.response?.status === 422 &&
          error.response?.data?.code === 31
        ) {
          throw new ReservationError(
            error.response?.data?.message ?? 'No quota error',
            ReservationErrorType.NO_QUOTA,
          )
        }
      }
      throw error
    }
  },
  addGuestsToReservation: async (
    reservationId,
    itineraryNumber,
    guests,
    token,
  ) => {
    try {
      const guestsDTO: ReservationGuestDTO[] = guests.map(guest => ({
        givenName: guest.name,
        lastName: guest.surname,
        email: guest.email,
        country: guest.country,
      }))

      return await reservationApiClient
        .modifiesReservation(itineraryNumber)
        .authorized(token)
        .put<undefined>(`/v1/reservations/${reservationId}/guests`, guestsDTO)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 410) {
          throw new ReservationError(
            error.response?.data?.message ?? 'Unknown error',
            ReservationErrorType.NON_EXISTENT,
          )
        }
        if (
          error.response?.status === 422 &&
          error.response?.data?.code === 29
        ) {
          throw new ReservationError(
            error.response?.data?.message ?? 'Unknown error',
            ReservationErrorType.EXPIRED,
          )
        }
      }
      throw error
    }
  },
  addIdCardToBooking: async (reservationId, itineraryNumber, idCard, token) => {
    try {
      const idCardDTO = {
        type: idCard.type,
        id: idCard.id,
      } satisfies IDCardDTO

      return await reservationApiClient
        .modifiesReservation(itineraryNumber)
        .authorized(token)
        .put<undefined>(
          `/v1/reservations/${reservationId}/guest-identification-card`,
          idCardDTO,
        )
    } catch (error) {
      if (error instanceof UnauthorizedError) {
        throw error
      }

      if (error instanceof AxiosError) {
        if (error.response?.status === 410) {
          throw new ReservationError(
            error.response?.data?.message ?? 'Unknown error',
            ReservationErrorType.NON_EXISTENT,
          )
        }
        if (
          error.response?.status === 422 &&
          error.response?.data?.code === 29
        ) {
          throw new ReservationError(
            error.response?.data?.message ?? 'Unknown error',
            ReservationErrorType.EXPIRED,
          )
        }
      }

      throw new IdCardError(
        `Error while trying to add idCard ${idCard.type} - ${idCard.id} to reservation with id: ${reservationId} and itineraryNumber: ${itineraryNumber}`,
        error,
      )
    }
  },
  deletePromotionalCoupon: async (
    cart: Cart,
    token: string | undefined,
    currency: CurrencyISOCode,
  ) => {
    const reservationRepository = apiReservationRepository({
      reservationApiClient,
      eventsManager,
      reservationStorageRepository,
      analytics,
      logger,
    })
    const reservationCriteria = mapCartIntoReservationCriteria(cart)

    const couponValue = reservationCriteria.roomStays.find(roomStay =>
      isDefined(roomStay.coupon),
    )?.coupon

    if (isUndefined(couponValue)) {
      throw new CannotDeleteCouponError()
    }

    const coupon = new Coupon('promotional', couponValue)
    reservationCriteria.roomStays = reservationCriteria.roomStays.map(
      roomStay => {
        return omit(roomStay, ['coupon'])
      },
    )

    const deletedCouponReservation = await reservationRepository.create(
      reservationCriteria,
      token,
      currency,
      { getConverted: true },
    )
    analytics.requests.preReservationFromCouponChange(deletedCouponReservation)

    reservationStorageRepository.setReservationFields(
      deletedCouponReservation.id,
      deletedCouponReservation.itineraryNumber,
    )

    eventsManager.emit(
      CustomEvent.UPDATED_PRE_RESERVE_FROM_RESERVE,
      deletedCouponReservation,
    )

    return { reservation: deletedCouponReservation, coupon }
  },
  applyPromotionalCoupon: async (
    coupon: Coupon,
    cart: Cart,
    token: string | undefined,
    currency: CurrencyISOCode,
  ) => {
    const reservationRepository = apiReservationRepository({
      reservationApiClient,
      eventsManager,
      reservationStorageRepository,
      analytics,
      logger,
    })
    const reservationCriteria = mapCartIntoReservationCriteria(cart)

    reservationCriteria.roomStays.forEach(roomStay => {
      roomStay.coupon = coupon.getValue()
    })

    const appliedCouponReservation = await reservationRepository.create(
      reservationCriteria,
      token,
      currency,
      { getConverted: true },
    )

    analytics.requests.preReservationFromCouponChange(appliedCouponReservation)

    reservationStorageRepository.setReservationFields(
      appliedCouponReservation.id,
      appliedCouponReservation.itineraryNumber,
    )

    const applies = appliesPromotionalCouponInReservation(
      appliedCouponReservation,
      coupon.getValue(),
    )
    if (applies) {
      eventsManager.emit(
        CustomEvent.UPDATED_PRE_RESERVE_FROM_RESERVE,
        appliedCouponReservation,
      )
    }

    return { reservation: appliedCouponReservation, coupon, applies }
  },
  removeExtras: async (reservationId, cart, priceOptions) => {
    try {
      const requestBody: ModifyExtrasRequestBodyDTO = {
        roomStays: cart.roomStays
          .map(roomStay => {
            return {
              id: roomStay.id,
              extras: [],
            }
          })
          .filter(isDefined),
      }

      const { itineraryNumber } =
        reservationStorageRepository.getReservationFields()

      const updatedReservationDTO = await reservationApiClient
        .modifiesReservation(itineraryNumber)
        .patch<ReservationDTO>(
          `/v1/reservations/${reservationId}/extras`,
          requestBody,
        )

      return mapReservation(updatedReservationDTO, priceOptions)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 410) {
          throw new ReservationError(
            error.response?.data?.message ?? 'Unknown error',
            ReservationErrorType.NON_EXISTENT,
          )
        }
        if (
          error.response?.status === 422 &&
          error.response?.data?.code === 29
        ) {
          throw new ReservationError(
            error.response?.data?.message ?? 'Unknown error',
            ReservationErrorType.EXPIRED,
          )
        }
      }
      throw error
    }
  },
  applyExtras: async (reservationId, itineraryNumber, cart) => {
    try {
      const requestBody: ModifyExtrasRequestBodyDTO = {
        roomStays: mapApplyExtrasRequest(cart),
      }

      const updatedReservationDTO = await reservationApiClient
        .modifiesReservation(itineraryNumber)
        .patch<ReservationDTO>(
          `/v1/reservations/${reservationId}/extras`,
          requestBody,
        )

      // No se piden precios convertidos ni se manda la moneda porque los precios
      // los queremos usar sin convertir en el caso de uso
      return mapReservation(updatedReservationDTO, { getConverted: false })
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 410) {
          throw new ReservationError(
            error.response?.data?.message ?? 'Unknown error',
            ReservationErrorType.NON_EXISTENT,
          )
        }
        if (
          error.response?.status === 422 &&
          error.response?.data?.code === 29
        ) {
          throw new ReservationError(
            error.response?.data?.message ?? 'Unknown error',
            ReservationErrorType.EXPIRED,
          )
        }
        if (
          error.response?.status === 422 &&
          error.response?.data?.code === 31
        ) {
          throw new ReservationError(
            error.response?.data?.message ?? 'No quota error',
            ReservationErrorType.NO_QUOTA,
          )
        }
      }
      throw error
    }
  },
  confirmationWithName: async (itineraryNumber: string) => {
    try {
      const { reservationId } =
        reservationStorageRepository.getReservationFields()

      await reservationApiClient
        .modifiesReservation(itineraryNumber)
        .post(`/v1/reservations/${reservationId}/confirmations`)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 410) {
          throw new ReservationError(
            error.response?.data?.message ?? 'Unknown error',
            ReservationErrorType.NON_EXISTENT,
          )
        }
        if (
          error.response?.status === 422 &&
          error.response?.data?.code === 29
        ) {
          throw new ReservationError(
            error.response?.data?.message ?? 'Unknown error',
            ReservationErrorType.EXPIRED,
          )
        }
      }
      throw error
    }
  },
  getViewingNowAccelerators: async (
    hotelId: string,
    checkIn: string,
    checkOut: string,
  ): Promise<ViewingAccelerator[]> => {
    try {
      const { data: viewingStats } =
        await reservationApiClient.get<ViewingStatsDTO>(
          '/v1/stats/pending-reservations',
          {
            params: {
              hotel_id: hotelId,
              start_date: Time.fromString(checkIn).format('YYYY-MM-DD'),
              end_date: Time.fromString(checkOut).format('YYYY-MM-DD'),
            },
          },
        )

      return mapViewingStats(viewingStats)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === 404) {
          return []
        }
        logger.error(error)
        return []
      }
      throw error
    }
  },

  addCommentsAndAddressToReservation: async (
    cart: Cart,
    token: string | undefined,
    currency: CurrencyISOCode,
    customerComments?: string,
    street?: Address['street'],
    postalCode?: Address['postalCode'],
  ) => {
    const reservationRepository = apiReservationRepository({
      reservationApiClient,
      eventsManager,
      reservationStorageRepository,
      analytics,
      logger,
    })
    const reservationCriteria = mapCartIntoReservationCriteria(cart)

    if (isDefined(customerComments)) {
      reservationCriteria.comment = customerComments
    }

    if (isDefined(street) && isDefined(postalCode)) {
      reservationCriteria.roomStays.forEach(roomStay => {
        if (isDefined(roomStay.guest)) {
          roomStay.guest.address = {
            ...roomStay.guest.address,
            street,
            postalCode,
          }
        }
      })
    }

    const reservationWithCustomerComments = await reservationRepository.create(
      reservationCriteria,
      token,
      currency,
      { getConverted: true },
    )

    reservationStorageRepository.setReservationFields(
      reservationWithCustomerComments.id,
      reservationWithCustomerComments.itineraryNumber,
    )

    eventsManager.emit(
      CustomEvent.UPDATED_PRE_RESERVE_FROM_RESERVE,
      reservationWithCustomerComments,
    )

    return reservationWithCustomerComments
  },
})

function mapReservationCriteriaToRoomStaysDTO(
  reservationCriteria: ReservationCriteria,
) {
  return reservationCriteria.roomStays.map(roomStay => {
    const { guest, originalTotalPrice, ...rest } = roomStay
    const hasOriginalPrice = isDefined(originalTotalPrice)

    const roomStayCriteria = {
      ...rest,
      hotelId: reservationCriteria.hotelId,
      guests: isDefined(guest)
        ? [
            {
              givenName: guest.name,
              lastName: guest.surname,
              email: guest.email,
              address: guest.address,
            },
          ]
        : [],
      occupancy: {
        ...roomStay.occupancy,
        children: roomStay.occupancy.children ?? undefined,
        childrenAges: roomStay.occupancy.childrenAges ?? undefined,
      },
      ...(hasOriginalPrice && {
        originalPrice: {
          stay: {
            base: originalTotalPrice?.base,
            total: originalTotalPrice?.total,
          },
        },
      }),
    }

    return roomStayCriteria
  })
}

const tryToCreateWithRollback = async (
  reservationCriteria: ReservationCriteria,
  reservationApiClient: ReservationApiClient,
  reservationFields: ReservationFields,
  token: string | undefined,
  currency: CurrencyISOCode,
  priceOptions: PriceOptions,
): Promise<Reservation> => {
  try {
    const reservationId = reservationFields.reservationId

    const reservationRequestDTO: CreateReservationRequestDTO = {
      relatedReservationId: reservationId,
      roomStays: mapReservationCriteriaToRoomStaysDTO(reservationCriteria),
      marketCampaign: reservationCriteria.marketCampaign,
      comment: reservationCriteria.comment,
    }

    const reservationDTO = await reservationApiClient
      .modifiesReservation(reservationFields.itineraryNumber)
      .authorized(token)
      .post<ReservationDTO>('/v1/reservations', reservationRequestDTO, {
        headers: {
          'X-CBE-Customer-Currency': currency,
        },
      })

    return mapReservation(reservationDTO, priceOptions)
  } catch (error) {
    if (!(error instanceof ItineraryNumberError)) {
      throw error
    }
    return await createWithoutRollback(
      reservationCriteria,
      reservationApiClient,
      token,
      currency,
      priceOptions,
    )
  }
}

const createWithoutRollback = async (
  reservationCriteria: ReservationCriteria,
  reservationApiClient: ReservationApiClient,
  token: string | undefined,
  currency: CurrencyISOCode,
  priceOptions: PriceOptions,
): Promise<Reservation> => {
  const reservationRequestDTO: CreateReservationRequestDTO = {
    roomStays: mapReservationCriteriaToRoomStaysDTO(reservationCriteria),
    marketCampaign: reservationCriteria.marketCampaign,
    comment: reservationCriteria.comment,
  }

  const reservationDTO = await reservationApiClient
    .authorized(token)
    .post<ReservationDTO>('/v1/reservations', reservationRequestDTO, {
      headers: {
        'X-CBE-Customer-Currency': currency,
      },
    })

  return mapReservation(reservationDTO, priceOptions)
}
