export type QueryParamKey =
  | 'marketprice'
  | 'hotel'
  | 'rooms'
  | 'mealplan'
  | 'roomcode'
  | 'arrive'
  | 'depart'
  | 'adult'
  | 'child'
  | 'childages'
  | 'reservationId'
  | 'paymentStatus'
  | 'locale'
  | 'store'
  | 'code'
  | 'logged'
  | 'country'
  | 'coupon'
  | 'specialCoupon'
  | 'groupcode'
  | 'currency'
  | 'hash'
  | 'orderNumber'
  | 'paymentCurrency'
  | 'statusCode'
  | 'paymentMethodCode'
  | 'token'
  | 'transactionId'
  | 'paymentAmount'
  | 'orderSignature'
  | 'merchantAccount'
  | 'extraId'
  | 'redirectView'
  | 'marketcampaign'
  | 'fee'
  | 'roomStay'
  | 'itineraryNumber'

export type QueryParams = {
  [key in QueryParamKey]?: QueryParamValue
}

export interface QueryParamsErrors {
  hasGuestError: boolean | undefined
  hasDatesError: boolean | undefined
  hasMarketPriceError: boolean | undefined
  hasCurrencyError: boolean | undefined
  hasHotelError: boolean | undefined
}

export const urlSeparator = '|'

export type RequiredParams = {
  market: string
  hotel: string
  checkIn: string
  checkOut: string
  adults: string
  currency: string
  [other: string]: string | undefined
}

export type QueryParamValue = string | undefined
