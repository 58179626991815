import { ContactRepository } from '../domain/Contact.repository'
import { WithInjectedParams } from 'src/core/Shared/_di/types'
import { AxiosError, HttpStatusCode } from 'axios'
import { Logger } from 'src/core/Shared/domain/Logger'
import { ContactApiClient } from 'src/core/Shared/infrastructure/contactApiClient'
import { CommercialCommunicationsDTO } from './Contact.api.dto'
import { mapCommercialCommunications } from './mapCommercialCommunications'

interface AllContactRepositoryDependencies {
  contactApiClient: ContactApiClient
  logger: Logger
}

export const apiContactRepository: WithInjectedParams<
  ContactRepository,
  AllContactRepositoryDependencies
> = ({ contactApiClient, logger }) => ({
  getCommercialCommunications: async email => {
    try {
      const { data } = await contactApiClient.get<CommercialCommunicationsDTO>(
        `/v1/contacts/${encodeURIComponent(email)}`,
        {},
      )

      return mapCommercialCommunications(data)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.NotFound) {
          logger.error(error)
          return {
            id: email,
            areActive: false,
          }
        }
      }

      logger.error(error)
      return {
        id: email,
        areActive: false,
      }
    }
  },
  updateCommercialCommunications: async (
    email: string,
    name: string,
    surname: string,
    language: string,
  ) => {
    try {
      const { data } = await contactApiClient.put<CommercialCommunicationsDTO>(
        `/v1/contacts/${encodeURIComponent(email)}`,
        {
          name,
          surname,
          language,
        },
      )

      return mapCommercialCommunications(data)
    } catch (error) {
      if (error instanceof AxiosError) {
        if (error.response?.status === HttpStatusCode.NotFound) {
          logger.error(error)
          return {
            id: email,
            areActive: false,
          }
        }
      }

      logger.error(error)
      return {
        id: email,
        areActive: false,
      }
    }
  },
})
