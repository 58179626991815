import { FC } from 'react'

export const ErrorIconNotificationDark: FC = () => (
  <>
    <rect width="24" height="24" rx="12" fill="#960b0d" />

    <circle
      cx="12"
      cy="12"
      r="6"
      stroke="#ffffff"
      strokeWidth="1"
      fill="none"
    />

    <path
      d="M8 8L16 16"
      stroke="#ffffff"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </>
)

export default ErrorIconNotificationDark
