import { isUndefined } from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import type { WithInjectedParams } from 'src/core/Shared/_di/types'
import type { StorageClient } from 'src/core/Shared/infrastructure/sessionStorageClient'
import type { Cryptography } from 'src/core/Shared/infrastructure/cryptography'
import type { ReservationGuest } from 'src/core/Shared/domain/Reservation.model'
import {
  ItineraryNumberError,
  ItineraryNumberErrorType,
} from 'src/core/Reservation/domain/ItineraryNumberError'
import type { Sentry } from 'src/core/Shared/infrastructure/errors/sentry'

export type ReservationFields = {
  reservationId: string
  itineraryNumber: string
  isPaid: boolean
}

interface ReservationManagerDependencies {
  sessionStorageClient: StorageClient
  cryptography: Cryptography
  sentry: Sentry
}

export interface ReservationStorageRepository {
  setReservationFields: (reservationId: string, itineraryNumber: string) => void
  getUnsafeReservationFields: () => ReservationFields | undefined
  getReservationFields: () => ReservationFields
  setReservationAlreadyPaid: () => void
  removeMaxVisitedStep: () => void
  setGuestData: (guestData: ReservationGuest) => void
  getGuestData: () => ReservationGuest | undefined
  removeReservationFields: () => void
}

export const reservationStorageRepository: WithInjectedParams<
  ReservationStorageRepository,
  ReservationManagerDependencies
> = ({ sessionStorageClient, cryptography, sentry }) => ({
  setReservationFields: (reservationId, itineraryNumber) => {
    const encodedFields = {
      ...encodeReservationFields(cryptography, reservationId, itineraryNumber),
      isPaid: false,
    }

    sessionStorageClient.set('requiredParamsToGetReservation', encodedFields)
  },
  getUnsafeReservationFields: () => {
    const reservationFields = sessionStorageClient.get<
      ReservationFields | undefined
    >('requiredParamsToGetReservation')

    if (isUndefined(reservationFields)) {
      return
    }

    const reservationId = cryptography.decodeBase64(
      reservationFields.reservationId,
    )
    const itineraryNumber = cryptography.decodeBase64(
      reservationFields.itineraryNumber,
    )

    return {
      reservationId,
      itineraryNumber,
      isPaid: reservationFields.isPaid,
    }
  },
  getReservationFields: () => {
    const reservationFields = sessionStorageClient.get<
      ReservationFields | undefined
    >('requiredParamsToGetReservation')

    if (isUndefined(reservationFields)) {
      throw new ItineraryNumberError(
        `Itinerary number not found in reservation fields`,
        ItineraryNumberErrorType.NotFound,
      )
    }

    const reservationId = cryptography.decodeBase64(
      reservationFields.reservationId,
    )
    const itineraryNumber = cryptography.decodeBase64(
      reservationFields.itineraryNumber,
    )

    return {
      reservationId,
      itineraryNumber,
      isPaid: reservationFields.isPaid,
    }
  },
  setReservationAlreadyPaid: () => {
    const repository = reservationStorageRepository({
      sessionStorageClient,
      cryptography,
      sentry,
    })

    const reservationFields = repository.getReservationFields()
    if (isUndefined(reservationFields)) {
      return
    }

    const encodedFields = {
      ...encodeReservationFields(
        cryptography,
        reservationFields.reservationId,
        reservationFields.itineraryNumber,
      ),
      isPaid: true,
    }

    sessionStorageClient.set('requiredParamsToGetReservation', encodedFields)
  },
  removeMaxVisitedStep: () => sessionStorageClient.remove('maxVisitedStep'),
  setGuestData: (guestData: ReservationGuest) => {
    sessionStorageClient.set('guestData', guestData)
  },
  getGuestData: () => {
    return sessionStorageClient.get<ReservationGuest | undefined>('guestData')
  },
  removeReservationFields: () =>
    sessionStorageClient.remove('requiredParamsToGetReservation'),
})

const encodeReservationFields = (
  cryptography: Cryptography,
  reservationId: string,
  itineraryNumber: string,
) => {
  return {
    reservationId: cryptography.encodeBase64(reservationId),
    itineraryNumber: cryptography.encodeBase64(itineraryNumber),
  }
}
