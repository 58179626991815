import { AnalyticsRequestReservation } from 'src/core/Shared/domain/Analytics'
import {
  flattenDeep,
  isDefined,
  uniq,
  head,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { isAppliedExtraPerUnit } from 'src/core/Shared/domain/Reservation.model'
import { AppliedExtra } from 'src/core/Shared/domain/Extra.model'
import { container } from 'src/core/Shared/_di'
import { Time } from 'src/core/Shared/infrastructure/Time'

export const reservation: AnalyticsRequestReservation = reservation => {
  const firstExtraFound = head(
    reservation.roomStays.find(roomStay => {
      return roomStay.extras.find(extra => extra.applied)
    })?.extras,
  )

  const extrasName = uniq(
    flattenDeep(
      reservation.roomStays.map(roomStay => {
        return roomStay.extras
          .map(extra => {
            if (extra.applied) {
              return extra.name
            }
          })
          .filter(isDefined)
      }),
    ),
  )

  const extrasId = uniq(
    flattenDeep(
      reservation.roomStays.map(roomStay => {
        return roomStay.extras.map(extra => {
          return extra.id
        })
      }),
    ),
  )

  const extraUnits = extrasId.map(extraId => {
    return flattenDeep(
      reservation.roomStays.map(roomStay => {
        return roomStay.extras
          .filter(extra => extra.id === extraId)
          .map(extra => {
            if (!extra.applied) {
              return
            }

            if (isAppliedExtraPerUnit(extra)) {
              return extra.quantity
            }

            return extra.adultsQuantity + extra.childrenQuantity
          })
          .filter(isDefined)
      }),
    ).reduce((a, b) => a + b, 0)
  })

  const extraPricesPerGuests = extrasId.map(extraId => {
    return flattenDeep(
      reservation.roomStays.map(roomStay => {
        return roomStay.extras
          .filter(extra => extra.id === extraId)
          .map(extra => {
            if (!extra.applied) {
              return
            }

            return extra.summary.grossPrice.value
          })
          .filter(isDefined)
      }),
    ).reduce((a, b) => a + b, 0)
  })

  const extraTotalPrice = extraPricesPerGuests.reduce((a, b) => a + b, 0)

  const viewParams = {
    availability_link: false,
    ecommerce_action: 'purchase',
    ecommerce_actionField_step: '4',
    ecommerce_action_field_list_gtag: 'step 4',
    ecommerce_action_field_step_gtag: '4',
    ecommerce_action_gtag: 'purchase',

    order_id: reservation.itineraryNumber,
    booked_status: reservation.status,

    booked_adults: reservation.occupancy.adults,
    booked_availability: !!reservation ? true : undefined,
    booked_board_basis: reservation.roomStays.map(
      roomStay => roomStay.rate.mealPlan.id,
    ),
    booked_check_in_date: Time.fromDate(reservation.checkIn).format('YYYYMMDD'),
    booked_check_out_date: Time.fromDate(reservation.checkOut).format(
      'YYYYMMDD',
    ),
    booked_check_in_hyphen: Time.fromDate(reservation.checkIn).format(
      'YYYY-MM-DD',
    ),
    booked_check_out_hyphen: Time.fromDate(reservation.checkOut).format(
      'YYYY-MM-DD',
    ),
    booked_nights: Time.fromDate(reservation.checkOut).diff(
      Time.fromDate(reservation.checkIn),
      'day',
    ),
    booked_children: reservation.occupancy.children, // children
    booked_rate_type: reservation.roomStays.map(roomStay =>
      roomStay.cancellation.flexible ? 'flexible' : 'non-refundable',
    ),
    booked_hotel_currency_price: reservation.prices.total.original.value,
    booked_hotel_currency: reservation.prices.total.original.currency,

    booked_hotel_id: reservation.hotel.id,
    booked_hotel_name: reservation.hotel.name,

    booked_rate_code: reservation.roomStays.map(roomStay => roomStay.rate.id),
    booked_room_price: reservation.roomStays.map(
      roomStay => roomStay.rate.total.grossPrice.value,
    ),
    booked_room_quantity: reservation.roomStays.length,
    booked_room_rate_type: reservation.roomStays.map(roomStay =>
      roomStay.rate.isMember ? 'myBarcelo' : 'Standard',
    ),

    booked_rooms: reservation.roomStays.map(roomStay => {
      return {
        room_adults: roomStay.occupancy.adults,
        room_children: roomStay.occupancy.children,
        room_children_age: roomStay.occupancy.childrenAges,
        room_type: roomStay.room.name,
        room_type_id: roomStay.room.id,
        room_board_basis: roomStay.rate.mealPlan.name,
        room_rate_code: roomStay.rate.id,

        hotel_currency_room_price: roomStay.rate.total.grossPrice.value,
        hotel_currency: roomStay.rate.total.grossPrice.currency,
      }
    }),

    booked_subtotal: reservation.prices.total.original.value,
    booked_tax: reservation.roomStays.reduce(
      (totalTax, roomStay) => totalTax + roomStay.rate.total.grossPrice.value,
      0,
    ),
    booked_user_currency: reservation.prices.total.converted.currency,
    booked_user_currency_price: reservation.prices.total.converted.value,

    booked_total_guests: !!reservation
      ? reservation.occupancy.adults + reservation.occupancy.children
      : undefined,
    booked_extras: extrasName,
    booked_extras_id: extrasId,
    booked_extras_units: extraUnits,
    booked_extras_price: extraPricesPerGuests,
    booked_extras_total_price: extraTotalPrice,
    booked_extras_currency: isUndefined(firstExtraFound)
      ? undefined
      : (firstExtraFound as AppliedExtra).summary.grossPrice.currency,
  }
  container
    .resolve('logger')
    .info('Analytics/links/requests/reservation - view', {
      viewParams,
      utag_data: window.utag_data,
    })
  window.utag.view(viewParams)
}
