import { Image } from 'src/ui/components/atoms/Image'
import { useState, FC, useRef, useEffect } from 'react'
import { A11y, Keyboard, Pagination, Virtual } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Swiper as SwiperClass } from 'swiper/types'
import styles from './Carousel.module.scss'
import 'swiper/css'
import 'swiper/css/a11y'
import 'swiper/css/keyboard'
import 'swiper/css/pagination'
import { NavigationPreviousButton } from './NavigationButton/NavigationPreviousButton'
import { NavigationNextButton } from './NavigationButton/NavigationNextButton'
import { classNames } from 'src/ui/utils/classnames'
import { useTrans } from 'src/ui/hooks/useTrans'
import { ImageLoader } from 'src/ui/utils/images'
import { useMedia } from 'src/ui/hooks/useMedia'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import FullScreenIcon from 'src/ui/components/atoms/Icon/_icons/interaction/FullScreenIcon'
import {
  isDefined,
  isUndefined,
} from 'src/core/Shared/infrastructure/wrappers/javascriptUtils'
import { useLanguageConfig } from 'src/ui/contexts/LanguageConfigContext'
import { RoomImageSlide } from 'src/core/Availability/domain/Availability.model'

import { FullScreenNavigationPreviousButton } from 'src/ui/components/molecules/CarouselFullScreen/FullScreenNavigationButton/FullScreenNavigationPreviousButton'
import { FullScreenNavigationNextButton } from 'src/ui/components/molecules/CarouselFullScreen/FullScreenNavigationButton/FullScreenNavigationNextButton'
import { RoomImagesFullScreen } from 'src/ui/components/molecules/Carousel/RoomImagesFullScreen'

interface Props {
  className?: string
  imageSlides: RoomImageSlide[]
  alt: string
  isRounded?: boolean
  loader: ImageLoader
  isFullScreenCarousel?: boolean
  hasToShowFullScreenButton?: boolean
  initialSlideIndex?: number
  onSlideChange?: (activeIndex: number) => void
}

export const Carousel: FC<Props> = ({
  className = 'carousel',
  imageSlides,
  alt,
  isRounded,
  loader,
  isFullScreenCarousel = false,
  hasToShowFullScreenButton = true,
  initialSlideIndex = 1,
  onSlideChange,
}) => {
  const { trans } = useTrans(['common'])
  const { media } = useMedia()
  const swiperRef = useRef<SwiperClass>()
  const [carouselIsHovered, setCarouselIsHovered] = useState(false)
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
  const languageConfig = useLanguageConfig()

  const isRTL = languageConfig.properties.isRTL
  useEffect(() => {
    if (isDefined(swiperRef) && isDefined(swiperRef.current)) {
      const swiper = swiperRef.current

      swiper.slideTo(initialSlideIndex, 0)
    }
  }, [swiperRef, initialSlideIndex])

  const hasMultipleSlides = imageSlides.length > 1
  const isLaptopOrDesktop = media === 'desktop' || media === 'laptop'

  const hasToShowNavigationButtons =
    hasMultipleSlides &&
    isLaptopOrDesktop &&
    carouselIsHovered &&
    !isFullScreenCarousel

  const hasToShowFullScreenNavigationButtons =
    hasMultipleSlides && isLaptopOrDesktop && isFullScreenCarousel

  const onSlideChangeHandler = (swiper: SwiperClass) => {
    onSlideChange && onSlideChange(swiper.realIndex)
  }

  return (
    <>
      {isFullScreen && (
        <RoomImagesFullScreen
          imageSlides={imageSlides}
          loader={loader}
          onClose={() => {
            setIsFullScreen(false)
          }}
          activeIndex={swiperRef.current?.activeIndex}
        />
      )}
      <div
        className={classNames(styles.carouselContainer)}
        onClick={() => {
          if (isFullScreenCarousel || !hasToShowFullScreenButton) return

          setIsFullScreen(true)
        }}
        onMouseEnter={() => {
          if (isFullScreenCarousel) return

          setCarouselIsHovered(true)
        }}
        onMouseLeave={() => {
          if (isFullScreenCarousel) return

          setCarouselIsHovered(false)
        }}
      >
        {isFullScreenCarousel && <div className={styles.securityZone}></div>}
        <div
          className={classNames(styles.carouselContent)}
          onClick={e => {
            if (isFullScreen) {
              e.stopPropagation()
            }
          }}
        >
          <Swiper
            a11y={{
              enabled: true,
              prevSlideMessage: trans('swiper_previous-slide'),
              nextSlideMessage: trans('swiper_next-slide'),
              firstSlideMessage: trans('swiper_first-slide'),
              lastSlideMessage: trans('swiper_last-slide'),
              paginationBulletMessage: trans('swiper_go-to-slide'),
            }}
            aria-label={trans('swiper_carousel-heading', { alt })}
            className={classNames(
              className,
              styles.carousel,
              isRounded && styles.roundedCorners,
              isFullScreenCarousel && styles.carousel_fullScreen,
            )}
            initialSlide={initialSlideIndex}
            keyboard={{
              enabled: true,
            }}
            loop={hasMultipleSlides}
            modules={[A11y, Keyboard, Pagination, Virtual]}
            onSwiper={swiper => {
              if (isUndefined(swiperRef)) return

              swiperRef.current = swiper
            }}
            onSlideChange={onSlideChangeHandler}
            pagination={{
              enabled: true,
            }}
            slidesPerView={1}
            tag="section"
            virtual={hasMultipleSlides}
          >
            {!isFullScreenCarousel && hasToShowFullScreenButton && (
              <Icon
                className={styles.fullScreenButton}
                size="l"
                icon={FullScreenIcon}
              />
            )}
            <h3
              aria-hidden
              id={`carouselHeading of ${alt}`}
              className={styles.visuallyHidden}
            >
              {alt}
            </h3>
            {imageSlides.map((imageSlide, index) => {
              return (
                <SwiperSlide
                  className={styles.slide}
                  key={imageSlide.src}
                  tabIndex={-1}
                >
                  {({ isVisible }) => (
                    <Image
                      className={styles.img}
                      alt={imageSlide.alt}
                      aria-hidden={!isVisible}
                      src={imageSlide.src}
                      height={810}
                      width={1440}
                      loader={loader}
                      quality={75}
                      priority={index === 0}
                    />
                  )}
                </SwiperSlide>
              )
            })}
            {hasToShowNavigationButtons && (
              <>
                {hasToShowNavigationButtons && (
                  <NavigationPreviousButton
                    isRTL={isRTL}
                    loop={hasMultipleSlides}
                  />
                )}
                <NavigationNextButton isRTL={isRTL} loop={hasMultipleSlides} />
              </>
            )}
          </Swiper>
          {isDefined(swiperRef) &&
            isDefined(swiperRef.current) &&
            hasToShowFullScreenNavigationButtons && (
              <>
                <FullScreenNavigationPreviousButton
                  swiperRef={swiperRef.current}
                  isRTL={isRTL}
                />
                <FullScreenNavigationNextButton
                  swiperRef={swiperRef.current}
                  isRTL={isRTL}
                />
              </>
            )}
        </div>
        {isFullScreenCarousel && (
          <div
            className={classNames(
              styles.securityZone,
              styles.securityZone_right,
            )}
          ></div>
        )}
      </div>
    </>
  )
}
