import styles from './Link.module.scss'
import { Text } from 'src/ui/components/atoms/Text'
import { FC, ReactNode } from 'react'
import { classNames } from 'src/ui/utils/classnames'
import { FontType } from 'src/ui/styles/settings'

type Target = '_blank' | '_self' | '_parent	' | '_top	'

type Variant = 'primary' | 'secondary' | 'simple'

interface Props {
  children: ReactNode
  link: string
  fontStyle?:
    | FontType
    | {
        mobile: FontType
        tablet?: FontType
        laptop?: FontType
        desktop?: FontType
      }
  variant?: Variant
  className?: string
  target?: Target
  [key: string]: any
}

export const Link: FC<Props> = ({
  children,
  link,
  variant = 'primary',
  fontStyle,
  className,
  target = '_blank',
  ...rest
}) => {
  return (
    <a
      target={target}
      rel="noreferrer"
      href={link}
      className={classNames(styles[variant], className)}
      {...rest}
    >
      {variant !== 'simple' ? (
        <Text fontStyle={fontStyle ?? 'link'} className={styles.text} {...rest}>
          {children}
        </Text>
      ) : (
        <Text
          fontStyle={fontStyle ?? 'xs-300'}
          color="mid"
          className={styles.simple}
          {...rest}
        >
          {children}
        </Text>
      )}
    </a>
  )
}
