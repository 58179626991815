import { User, UserLevel, UserLevelV2 } from '../domain/User'
import { UserDTO } from './User.api.dto'
import { mapDiscount } from './mapDiscount'
import { isFeatureFlagEnabled } from 'src/core/Shared/infrastructure/featureFlags'

export const mapUser = (userDto: UserDTO): User => {
  const hasNewMyBarceloLoyalties = isFeatureFlagEnabled(
    'FF_FEATURE_5847_MY_BARCELO_REVAMP',
  )

  return {
    email: userDto.email,
    givenName: userDto.given_name,
    familyName: userDto.family_name,
    middleName: userDto.middle_name,
    discount: mapDiscount(userDto.discount),
    level: hasNewMyBarceloLoyalties
      ? mapUserLevelV2(userDto.profile_level_code)
      : mapUserLevel(userDto.profile_level_code),
    address: userDto.address,
    city: userDto.city,
    crmId: userDto.crm_id,
    crsId: userDto.crs_id,
    phoneNumber: userDto.phone_number,
    postalCode: userDto.postalCode,
    profileLevelCode: userDto.profile_level_code,
    state: userDto.state,
    street: userDto.street,
  }
}

const mapUserLevelV2 = (
  userLevel: UserDTO['profile_level_code'] | undefined,
): UserLevelV2 => {
  switch (userLevel) {
    case undefined:
      return UserLevelV2.NotFound
    case '-1':
      return UserLevelV2.NotFound
    case '1':
      return UserLevelV2.Essential
    case '2':
      return UserLevelV2.Special
    case '3':
      return UserLevelV2.Unique
    default:
      return UserLevelV2.NotFound
  }
}

const mapUserLevel = (
  userLevel: UserDTO['profile_level_code'] | undefined,
): UserLevel => {
  switch (userLevel) {
    case undefined:
      return UserLevel.NotFound
    case '-1':
      return UserLevel.NotFound
    case '1':
      return UserLevel.Start
    case '2':
      return UserLevel.Intense
    case '3':
      return UserLevel.Unique
    default:
      return UserLevel.NotFound
  }
}
