import { classNames } from 'src/ui/utils/classnames'
import { useSwiper } from 'swiper/react'
import { Icon } from 'src/ui/components/atoms/Icon/Icon'
import React, { FC } from 'react'

import styles from './NavigationButton.module.scss'
import { ChevronIcon } from 'src/ui/components/atoms/Icon/_icons/navigation/ChevronIcon'

interface Props {
  isRTL: boolean
  loop?: boolean
}

export const NavigationNextButton: FC<Props> = ({ isRTL, loop = false }) => {
  const swiper = useSwiper()

  function handleNextSlide() {
    return !isRTL ? swiper.slideNext?.() : swiper.slidePrev?.()
  }

  if (!loop && (isRTL ? swiper.isBeginning : swiper.isEnd)) {
    return null
  }

  return (
    <div
      onClick={(event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation()
        handleNextSlide()
      }}
      className={classNames(styles.navigation, styles.rightNavigation)}
    >
      <Icon size="s" icon={ChevronIcon} />
    </div>
  )
}
