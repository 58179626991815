import { CommercialCommunicationsDTO } from 'src/core/User/infrastructure/Contact.api.dto'
import { CommercialCommunications } from 'src/core/User/domain/Contact.model'

export const mapCommercialCommunications = (
  commercialCommunicationsDTO: CommercialCommunicationsDTO,
): CommercialCommunications => {
  return {
    id: commercialCommunicationsDTO.id,
    areActive: commercialCommunicationsDTO.commercialCommunications,
  }
}
