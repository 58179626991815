import { FC } from 'react'

interface Props {
  color: string
}

export const CloseIcon: FC<Props> = ({ color }) => (
  <>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M18.2696 17.5001L18.2831 17.4866L12.5557 11.7593L18.3529 5.96207C18.4554 5.85955 18.5185 5.71873 18.5185 5.56327C18.5185 5.25235 18.2662 5 17.9553 5C17.7998 5 17.659 5.06309 17.5565 5.1656L11.7593 10.9628L5.96207 5.1656C5.85955 5.06309 5.71873 5 5.56327 5C5.25235 5 5 5.25235 5 5.56327C5 5.71873 5.06309 5.85955 5.1656 5.96094L10.9628 11.7593L5.1656 17.5565C5.06309 17.659 5 17.7998 5 17.9553C5 18.2662 5.25235 18.5185 5.56327 18.5185C5.71873 18.5185 5.85955 18.4554 5.96207 18.3529L11.7593 12.5557L17.4866 18.2831L17.5001 18.2696C17.6015 18.416 17.7637 18.5185 17.9553 18.5185C18.2662 18.5185 18.5185 18.2662 18.5185 17.9553C18.5185 17.7626 18.416 17.6015 18.2696 17.5001Z"
        fill={color}
      />
    </svg>
  </>
)

export default CloseIcon
